import { PURCHASES_RECEIVED, SET_PURCHASE } from "../types";

export default (state, { type, payload }) => {
  switch (type) {
    case PURCHASES_RECEIVED:
      return { ...state, purchases: payload };
    case SET_PURCHASE:
      return { ...state, purchase: payload };
    default:
      return { ...state };
  }
};
