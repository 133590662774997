import { navigate } from "@reach/router";
import React, { createContext, useReducer } from "react";
import CursosReducer from "../reducers/CursosReducer";
import CursosService from "../services/CursosService";
import {
  CREATE_CURSO,
  CURSOS_RECIBIDOS,
  HIDE_SPINNER,
  SET_PROPIEDAD_CURSO,
  SHOW_SPINNER,
  SINGLE_CURSO_RECIBIDO,
} from "../types";

const initialState = {
  spinner: false,
  cursos: null,
  curso: null,
};

export const CursosContext = createContext(initialState);

export const CursosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CursosReducer, initialState);

  const getCursos = () => {
    CursosService.getCursos().then((res) => {
      const { courses } = res.data;
      dispatch({ type: CURSOS_RECIBIDOS, payload: courses });
    });
  };

  const getSingleCurso = (course_id) => {
    if (parseInt(course_id) === 2) {
      return navigate("/mylatina/certification");
    }
    CursosService.getSingleCurso(course_id)
      .then((res) => {
        const { course } = res.data;
        dispatch({ type: SINGLE_CURSO_RECIBIDO, payload: course });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            navigate("/mylatina/sorry");
          }
        }
      });
  };

  const createCurso = () => {
    dispatch({ type: CREATE_CURSO });
  };

  const setPropiedadCurso = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CURSO, payload: { key, value } });
  };

  const postCurso = (curso) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: HIDE_SPINNER });
  };

  return (
    <CursosContext.Provider
      value={{
        ...state,
        getCursos,
        getSingleCurso,
        createCurso,
        setPropiedadCurso,
        postCurso,
      }}
    >
      {children}
    </CursosContext.Provider>
  );
};
