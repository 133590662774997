import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import moment from "moment";
import { BASE_URL, S3_ENDPOINT } from "../utils";

const MiInformacion = () => {
  const [editMode, setEditMode] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [picture, setPicture] = useState(null);

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(UserContext);

  useEffect(() => {
    if (user !== null) {
      if (user.newFile && user.newFile !== null && picture === null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPicture(e.target.result);
        };
        reader.readAsDataURL(user.newFile);
      }
    }
  }, [user]);

  const handleSubmit = (e) => {
    setEditMode(false);
    e.preventDefault();
    updateUsuario(user);
  };

  const handleFiles = (e) => {
    const file = e.target.files[0];
    setPropiedadUser("newFile", file);
  };

  const renderImage = () => {
    const { file_id, file } = user;
    if (file !== null)
      return (
        <div className="col-4 col-md-4">
          <img
            src={`${S3_ENDPOINT}/${file_id}.${file.type}`}
            className="video-form-thumbnail d-block my-3"
          />
        </div>
      );
  };

  const renderImageForm = () => {
    if (changeImage || isNaN(user.file_id) || user.file_id === null) {
      return (
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      );
    }
    return (
      <>
        <button
          className="btn btn-outline-dark d-block"
          onClick={() => setChangeImage(true)}
        >
          Cambiar Imagen
        </button>
        <button
          className="btn btn-outline-danger mt-5"
          onClick={() => setPropiedadUser("file_id", null)}
        >
          Quitar Imagen
        </button>
      </>
    );
  };

  const renderInformacion = () => {
    const {
      file,
      file_id,
      name,
      last_name,
      birthdate,
      instagram,
      phone,
      email,
    } = user;
    if (editMode) {
      return (
        <form onSubmit={handleSubmit}>
          <div className="row">
            {renderImage()}
            <div className="col-8 col-md-8">
              <label>Foto</label>
              {renderImageForm()}
            </div>
          </div>
          <label>Nombre:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropiedadUser("name", e.target.value)}
          />
          <label>Apellidos:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={last_name}
            onChange={(e) => setPropiedadUser("last_name", e.target.value)}
          />
          <label>Instagram:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={instagram}
            onChange={(e) => setPropiedadUser("instagram", e.target.value)}
          />
          <label>Fecha de Nacimiento:</label>
          <input
            type="date"
            className="form-control mb-3"
            value={moment(birthdate).format("YYYY-MM-DD")}
            onChange={(e) => setPropiedadUser("birthdate", e.target.value)}
          />
          <label>Telefono Celular</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={phone}
            onChange={(e) => setPropiedadUser("phone", e.target.value)}
          />
          <div className="row mt-2">
            <div className="col col-md-6">
              <button type="submit" className="btn btn-dark">
                Guardar
              </button>
            </div>
            <div className="col col-md-6 text-end">
              <button
                className="btn btn-link text-muted"
                onClick={() => {
                  setEditMode(false);
                  getUsuario();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
    return (
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col col-md-4">
            {file !== null && (
              <img
                src={`${S3_ENDPOINT}/${file_id}.${file.type}`}
                className="w-100 profile-image"
              />
            )}
          </div>
          <div className="col col-md-8">
            <h4 className="mb-3 pb-3 border-bottom">
              {name} {last_name}
            </h4>
            <div className="row">
              <div className="col-1">
                <i className="fa fa-birthday-cake"></i>
              </div>
              <div className="col-11">
                {birthdate === null
                  ? "N/D"
                  : moment(birthdate).utc().format("DD MMM YYYY")}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <i className="fa fa-envelope"></i>
              </div>
              <div className="col-11">{email}</div>
            </div>
            <div className="row">
              <div className="col-1">
                <i className="fa fa-phone"></i>
              </div>
              <div className="col-11">{phone}</div>
            </div>
            <div className="row">
              <div className="col-1">@</div>
              <div className="col-11">{instagram}</div>
            </div>
            <button
              className="btn btn-outline-primary mt-3"
              onClick={() => setEditMode(true)}
            >
              <i className="fa fa-edit"></i> Editar
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid pt-4">
      <h1 className="mb-4">Mi Información</h1>
      <div className="card p-3 shadow-sm no-scale">{renderInformacion()}</div>
    </div>
  );
};

export default MiInformacion;
