import React from "react";
import SingleMedia from "../media/SingleMedia";
import SingleResource from "../resources/SingleResource";

const SingleLesson = ({ lesson, completeLesson }) => {
  const renderMedia = () => {
    if (Array.isArray(lesson.media)) {
      return lesson.media.map((media) => (
        <SingleMedia key={media.lesson_media_id} media={media} />
      ));
    }
  };
  const renderResources = () => {
    if (Array.isArray(lesson.resources)) {
      return lesson.resources.map((resource) => (
        <SingleResource key={resource.lesson_resource_id} resource={resource} />
      ));
    }
  };

  return (
    <div className="container-fluid ps-0 pb-3 lesson-container">
      <div className="row align-items-center border-bottom pb-2 mb-2">
        <div className="col-6 col-md-6">
          <h2 className="mt-4">
            Lección #{lesson.order}: {lesson.name}
          </h2>
        </div>
        <div className="col-6 col-md-6 text-right pe-0">
          <button
            className={
              "btn mx-2 " +
              (lesson.completed.length > 0
                ? "btn-primary btn-lg bg-pink"
                : "btn-outline-dark")
            }
            onClick={() => completeLesson(lesson.lesson_id)}
          >
            <i className="fa fa-check"></i>
          </button>
        </div>
      </div>
      <p className="mb-4">{lesson.description}</p>
      {lesson.content !== null && lesson.content !== "" && (
        <div className="card no-scale mb-3">
          <p>{lesson.content}</p>
        </div>
      )}
      <h4 className="border-bottom mb-3 pb-2">Medios</h4>
      {renderMedia()}
      <h4 className="mt-4 border-bottom mb-3 pb-2">Descargables</h4>
      {renderResources()}
    </div>
  );
};

export default SingleLesson;
