import React, { useEffect, useState } from "react";
import { S3_ENDPOINT } from "../../utils";

const StudioSlideshow = () => {
  const [active, setActive] = useState(0);

  const slides = [
    {
      src: `${S3_ENDPOINT}/instructor-slide-1.jpg`,
    },
    {
      src: `${S3_ENDPOINT}/instructor-slide-2.jpg`,
    },
    {
      src: `${S3_ENDPOINT}/instructor-slide-3.jpg`,
    },
    {
      src: `${S3_ENDPOINT}/instructor-slide-4.jpg`,
    },
  ];

  useEffect(() => {
    /*let interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => {
      window.clearInterval(interval);
    };*/
  });

  const handleNext = () => {
    if (active === slides.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };

  const renderSlides = () => {
    if (Array.isArray(slides)) {
      return slides.map((slide, index) => (
        <div class={`carousel-item ${active === index ? "active" : ""}`}>
          <img
            className="d-block slideshow-img w-100 mw-100"
            src={slide.src}
            alt="First slide"
          />
          <div className="slideshow-text p-5">
            <div className="row h-100">
              <div className="col-12 col-md-4">
                <div className="slideshow-content">
                  <img
                    src={`${S3_ENDPOINT}/instructor-19.png`}
                    className="mb-4 slideshow-logo"
                  />
                  <div className="text-lg">
                    <p className="italic mb-0">
                      ¡Lleva el método Latina Tribe a tu ciudad!
                    </p>
                    <p>
                      Emprende tu propio negocio y sé tu propia jefa, empodera a
                      tu comunidad y diviértete bailando.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a
                        href="#precios"
                        className="btn btn-blue btn-lg mb-3 w-100 bebas bold"
                      >
                        BUY NOW
                      </a>
                    </div>
                    <div className="col-6"></div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8 h-600 hide-mobile">
                <div className="row align-items-end h-100">
                  <div className="container-fluid text-right mobile-left">
                    <h2>
                      1era Certificación{" "}
                      <span className="text-blue bebas">(SOLD OUT)</span>
                    </h2>
                    <p className="mb-0">18, 19 y 20 de Noviembre de 2022</p>
                    <p className="mb-0">Latina Studio, Mty NL</p>
                    <br className="mb-5" />
                    <h2>2da Certificación</h2>
                    <p className="mb-0">27, 28 y 29 de Enero de 2023</p>
                    <p className="mb-0">Latina Studio, Mty NL</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-overlay"></div>
        </div>
      ));
    }
  };

  const renderIndicators = () => {
    if (Array.isArray(slides)) {
      return slides.map((slide, index) => (
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to={index}
          onClick={() => setActive(index)}
          className={active === index ? "active" : ""}
        ></li>
      ));
    }
  };

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">{renderIndicators()}</ol>
      <div className="carousel-inner">{renderSlides()}</div>
    </div>
  );
};

export default StudioSlideshow;
