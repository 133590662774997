import React, { useState, useContext } from "react";
import { Link } from "@reach/router";
import { UserContext } from "../../context/UserContext";

const LoginForm = ({ signUpAction }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { spinner, signIn } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div id="login-card">
      <h2 className="text-center mb-3">Inicia Sesión</h2>
      <div className="vibe-card no-scale text-left shadow p-4">
        <form onSubmit={handleSubmit}>
          <label>Correo Electrónico</label>
          <input
            type="email"
            className="form-control mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>
            Contraseña{" "}
            <button
              className="btn btn-link text-muted text-left text-small py-0 mb-1"
              onClick={(e) => {
                e.preventDefault();
                togglePassword();
              }}
            >
              <span className="text-montserrat text-small text-auto">
                {showPassword ? "Ocultar" : "Mostrar"}
              </span>
            </button>
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit" className="btn pink-btn" value="Entrar">
            {spinner ? <div className="spinner-border"></div> : "Entrar"}
          </button>
        </form>
        <div className="container-fluid px-0 mt-4">
          ¿Aun no tienes cuenta?{" "}
          {signUpAction ? (
            <Link to="/signup" className="text-accent">
              Crea tu cuenta
            </Link>
          ) : (
            <Link to="/signup" className="text-accent">
              Crea tu cuenta
            </Link>
          )}
        </div>
        <div className="container-fluid px-0 mt-4">
          <Link to="/recuperar" className="text-muted">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
