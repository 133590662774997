import moment from "moment";
import React, { useContext, useEffect } from "react";
import CursoCard from "../components/cursos/CursoCard";
import { CursosContext } from "../context/CursosContext";
import { UserContext } from "../context/UserContext";
import PanelView from "./PanelView";

const Cursos = () => {
  const { cursos, getCursos } = useContext(CursosContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getCursos();
  }, []);

  const renderCursos = () => {
    if (Array.isArray(cursos)) {
      return cursos.map((curso) => (
        <CursoCard
          key={curso.course_id}
          curso={curso}
          purchase={
            Array.isArray(user.purchases)
              ? user.purchases.find(
                  (purchase) =>
                    purchase.course_id &&
                    moment(purchase.expiration_date).isAfter(moment())
                )
              : undefined
          }
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <PanelView title="Cursos">
      <div className="row">{renderCursos()}</div>
    </PanelView>
  );
};

export default Cursos;
