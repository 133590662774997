import {
  CREATE_CURSO,
  CURSOS_RECIBIDOS,
  SET_PROPIEDAD_CURSO,
  SINGLE_CURSO_RECIBIDO,
} from "../types";

const schema = {
  course_id: "nuevo",
  name: "",
  description: "",
  expiration_days: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case CURSOS_RECIBIDOS:
      return { ...state, cursos: payload };
    case SINGLE_CURSO_RECIBIDO:
      return { ...state, curso: payload };
    case SET_PROPIEDAD_CURSO:
      const { key, value } = payload;
      const curso = { ...state.curso };
      curso[key] = value;
      return { ...state, curso };
    case CREATE_CURSO:
      return { ...state, curso: schema };
    default:
      return { ...state };
  }
};
