import React, { createContext, useReducer } from "react";
import SectionsReducer from "../reducers/SectionsReducer";
import SectionsService from "../services/SectionsService";
import {
  CREATE_SECTION,
  SECTIONS_RECIBIDAS,
  SET_PROPIEDAD_SECTION,
  SINGLE_SECTION_RECIBIDA,
} from "../types";

const initialState = {
  sections: null,
  section: null,
};

export const SectionsContext = createContext(initialState);

export const SetctionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SectionsReducer, initialState);

  const getCourseSections = (course_id) => {
    SectionsService.getCourseSections(course_id).then((res) => {
      const { sections } = res.data;
      dispatch({ type: SECTIONS_RECIBIDAS, payload: sections });
    });
  };

  const createSection = () => {
    dispatch({ type: CREATE_SECTION });
  };

  const setSection = (section) => {
    dispatch({ type: SINGLE_SECTION_RECIBIDA, payload: section });
  };

  const setPropiedadSection = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_SECTION, payload: { key, value } });
  };

  return (
    <SectionsContext.Provider
      value={{
        ...state,
        getCourseSections,
        createSection,
        setPropiedadSection,
      }}
    >
      {children}
    </SectionsContext.Provider>
  );
};
