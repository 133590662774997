import React, { useEffect } from "react";
import Footer from "../components/global/Footer";
import SlideShow from "../components/global/Slideshow";
import Navbar from "../components/global/Navbar";
import { S3_ENDPOINT } from "../utils";
import "./Home.css";
import { Link } from "@reach/router";
import FAQ from "../components/home/FAQ";
import faqitems from "../components/home/faqitems";
import PromoBanner from "../components/home/PromoBanner";

const StudioLanding = () => {
  useEffect(() => {}, []);

  return (
    <div className="home-page bg-dark">
      <Navbar />
      <div id="video" className="row video-row">
        <SlideShow />
      </div>
      <div className="container-fluid p-5 show-mobile">
        <h2 className="mb-0">1era Certificación</h2>
        <h3 className="text-blue h1 display-3 bebas">(SOLD OUT)</h3>
        <p className="mb-0">18, 19 y 20 de Noviembre de 2022</p>
        <p className="mb-0">Latina Studio, Mty NL</p>
        <br className="mb-5" />
        <h2>2da Certificación</h2>
        <p className="mb-0">27, 28 y 29 de Enero de 2023</p>
        <p className="mb-0">Latina Studio, Mty NL</p>
      </div>
      <div className="container-fluid p-5">
        <div className="row align-items-center">
          <div className="container">
            <video
              className="mw-100 w-100 d-block mx-auto"
              poster={`${S3_ENDPOINT}/instructor-16-min.png`}
              controls
            >
              <source src={`${S3_ENDPOINT}/latina-trailer.mp4`}></source>
            </video>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 bebas">
        <div id="precios" className="container-fluid p-5">
          <img
            src={`${S3_ENDPOINT}/latina-certified.png`}
            className="w-100 mw-100 d-block my-4"
          />
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="mb-4">
                <h3 className="text-blue display-3">
                  Opción 1: Pago de Contado
                </h3>
                <p className="mb-0 display-1">$15,899 MXN</p>
                <p className="display-3">(795 USD)</p>
                <Link
                  to="/checkout/course/3"
                  className="btn btn-lg btn-blue my-3"
                >
                  COMPRAR DE CONTADO
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 text-large">
              <div className="mb-4">
                <h3 className="text-blue display-3">Opción 2: Pago a Plazos</h3>
                <p className="libre italic h3">
                  ¡Separa tu lugar con solo $3,000 MXN!
                </p>
                <p className="h1">Pago 1: $6,449.50 MXN (1 de Nov)</p>
                <p className="h1">Pago 2: $6,449.50 MXN (1 de Dic)</p>
                <p className="h1">Total = $15,899 MXN</p>
                <div className="row align-items-center">
                  <div className="col-12 col-md-6">
                    {" "}
                    <Link
                      to="/checkout/certificacion"
                      className="btn btn-lg btn-light mb-3 w-100"
                    >
                      COMPRAR A PAGOS
                    </Link>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="libre text-small">
                      *Los cargos se realizarán automáticamente a tu tarjeta.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 pt-0">
        <h2 className="text-center display-1 mb-4">WHAT YOU NEED TO KNOW</h2>
        <FAQ items={faqitems} />
      </div>
      <PromoBanner />
      <div className="container-fluid p-5">
        <div id="howtolatina" className="container-fluid p-5">
          <div className="row align-items-end">
            <div className="col-12 col-md-8">
              <img
                src={`${S3_ENDPOINT}/instructor-20.png`}
                className="howtolatina mb-5"
              />
              <div className="w-75">
                <p className="h2">
                  Tomar este curso es requisito para tomar la certificación de
                  instructora.
                </p>
                <h4 className="bebas">
                  <span className="display-5 text-stroke me-3">$1,600 MXN</span>
                  <span className="display-2">$800 MXN</span>
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-4 text-right mobile-left">
              <h2>¿Qué vas a aprender?</h2>
              <ul className="style-none">
                <li>Clase demo</li>
                <li>Técnica básica del método Latina</li>
                <li>Plática "The Latina Mindset"</li>
                <li>Pon de Replay coreografía original paso a paso</li>
              </ul>
              <Link
                to="/checkout/course/1"
                className="btn btn-lg btn-howtolatina btn-pink"
              >
                Comprar
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudioLanding;
