import React, { createContext, useReducer, useContext } from "react";
import LessonsReducer from "../reducers/LessonsReducer";
import LessonsService from "../services/LessonsService";
import { CursosContext } from "./CursosContext";
import { ModalContext } from "./ModalContext";

const initialState = {
  lessons: null,
};

export const LessonsContext = createContext(initialState);

export const LessonsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LessonsReducer, initialState);

  const { success } = useContext(ModalContext);

  const { getSingleCurso } = useContext(CursosContext);

  const completeLesson = (lesson_id, course_id) => {
    LessonsService.completeLesson(lesson_id).then(() => {
      success("¡Lección completada con éxito!");
      getSingleCurso(course_id);
    });
  };

  return (
    <LessonsContext.Provider value={{ ...state, completeLesson }}>
      {children}
    </LessonsContext.Provider>
  );
};
