import React from "react";
import { BASE_URL } from "../../utils";

const SingleMedia = ({ media }) => {
  const renderMedia = () => {
    const video_source = media.src;
    const { type } = media;
    if (type === "vimeo") {
      return (
        <iframe
          className="video-iframe"
          src={`https://player.vimeo.com/video/${video_source}`}
          allowFullScreen
        ></iframe>
      );
    }
    if (type === "youtube") {
      const source =
        "https://www.youtube.com/embed/" + video_source.split("v=")[1];
      return (
        <div className="p-3">
          <iframe
            src={source}
            title="YouTube video player"
            className="mw-100 w-100 shadow"
            height="500px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      );
    }
    if (type === "drive") {
      return (
        <div className="p-3">
          <iframe
            src={video_source}
            className="mw-100 w-100"
            height="auto"
            width="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      );
    }
    if (type === "mp4") {
      return (
        <div className="p-3">
          <video className="mw-100 w-100 mb-3" controls>
            <source
              src={`${BASE_URL}/adjuntos/${media.file_id}`}
              type="video/mp4"
            />
          </video>
        </div>
      );
    }
    return <img src={`${BASE_URL}/adjuntos/${media.file_id}`} />;
  };

  return <div className="my-2 card no-scale">{renderMedia()}</div>;
};

export default SingleMedia;
