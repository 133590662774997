import React from "react";
import latina_logo from "../../assets/images/latina_logo1.png";
import amex from "../../assets/images/amex.png";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import paypal from "../../assets/images/paypal.png";
import { S3_ENDPOINT } from "../../utils";

const Footer = () => {
  return (
    <div className="bg-dark py-5">
      <div className="container-fluid p-5 text-white">
        <div className="row">
          <div className="col-12 col-md-4 my-3">
            <a href="https://latinatribe.mx">
              <img src={latina_logo} className="logo-footer" />
            </a>
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="text-pink text-montserrat bold">CONTACTO</h4>
            <p>
              <img
                src={`${S3_ENDPOINT}/pin-latina.png`}
                className="thumbnail"
              />{" "}
              Monterrey, Nuevo León.
            </p>
            <p>
              <i className="fa fa-envelope me-2"></i> latinatribe@gmail.com
            </p>
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="text-pink text-montserrat bold">ACEPTAMOS</h4>
            <img src={amex} className="payment-method me-2" />
            <img src={mastercard} className="payment-method me-2" />
            <img src={visa} className="payment-method me-2" />
            <img src={paypal} className="payment-method me-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
