const faqitems = [
  {
    title: "¿Cuáles son los requisitos",
    htmlContent: `<ul>
              <li>Ser mayor de edad 18+</li>
              <li>
                Haber completado el curso online
                <a href="#howtolatina" className="">
                  “How To Latina”
                </a>
                (Psst! está al 50% de descuento)
              </li>
              <li>Adquirir el curso de certificación.</li>
              <li>
                <span className="">NO</span> vivir en Monterrey.
              </li>
              <li>Bichota energy AF!</li>
            </ul>`,
    title: "¿Qué incluye?",
    htmlContent: `<ul>
              <li>Curso teórico y práctico presencial de 3 días.</li>
              <li>Latina instructor kit </li>
              <li>Photoshoot + video content </li>
              <li>Manual de instructora (fisico y digital)</li>
              <li>Masterclass w/headcoaches</li>
              <li>
                Taller de negocios
                <span className="">"Become a Latina Boss"</span>
              </li>
              <li>1er mes de suscripción al portal de instructoras</li>
              <li>Snacks e hidratación</li>
              <li>Diploma y licencia de instructora. </li>
              <li>Sunday brunch & mimosas</li>
            </ul>`,
  },
  {
    title: "¿Qué vas a aprender?",
    htmlContent: `<ul>
              <li>
                <span className="">¿Qué significa ser Latina?</span>
                Nuestro manifesto, visión, misión y valores.
              </li>
              <li>Anatomía del cuerpo humano</li>
              <li>
                <span className="">¿Qué es el baile?</span> Beneficios
                mentales y físicos.
              </li>
              <li>Ritmo y factores del movimiento</li>
              <li>¿Qué hace a una instructora exitosa?</li>
              <li>Método de calentamiento y enfriamiento</li>
              <li>Aislamientos </li>
              <li>
                <span className="">Coreografias</span> originales del
                método Latina
              </li>
            </ul>`,
  },
  {
    title: "¿Cuál es el itinerario?",
    htmlContent: `
      <div className="container-fluid px-0">
        <h3>Viernes 27</h3>
        <p>10:00 a.m. - 5:00 p.m.</p>
        <p>Masterclass 6:00 p.m. (opcional)</p>
        <hr />
        <h3>Sábado 28</h3>
        <p>10:00 a.m. - 5:00 p.m.</p>
        <hr />
        <h3>Domingo 29</h3>
        <p>10:00 a.m. - 1:00 p.m.</p>
      </div>`,
  },
];

export default faqitems;
