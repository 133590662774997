import React from "react";
import { BASE_URL } from "../../utils";

const SingleResource = ({ resource }) => {
  const renderType = () => {
    const { type } = resource;
    switch (type) {
      case "youtube":
        return "fab fa-youtube";
      case "pdf":
        return "fas fa-file-pdf";
      case "excel":
        return "fas fa-file-excel";
      default:
        return "fas fa-file";
    }
  };

  const renderSource = () => {
    const { type } = resource;
    if (["excel", "pdf", "file"].includes(type) || resource.file_id !== null) {
      return `${BASE_URL}/files/${resource.file_id}`;
    }
    return resource.src;
  };

  return (
    <div className="col-12 col-md-4 text-center">
      <a
        className="card no-scale text-dark"
        href={renderSource()}
        target="_blank"
        rel="noreferrer"
      >
        <div className="d-block w-100 my-3">
          <i className={`fa-2x text-latina ${renderType()}`}></i>
        </div>
        <p>{resource.name}</p>
      </a>
    </div>
  );
};

export default SingleResource;
