import { Link } from "@reach/router";
import React from "react";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top py-2">
      <div className="container-fluid navbar-container">
        <a className="navbar-brand" href="#landing">
          <img src="/img/Latina_Icono2.png" className="logo-navbar" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid">
            <div className="row">
              <div className="col col-md-8 col-xl-10">
                <ul className="navbar-nav">
                  <li className="nav-item hover-pink">
                    <a className="nav-link" href="#howtolatina">
                      WORKSHOP
                    </a>
                  </li>
                  <li className="nav-item hover-blue">
                    <a className="nav-link" href="#precios">
                      CERTIFICATION
                    </a>
                  </li>
                  <li className="nav-item show-mobile">
                    <Link
                      className="btn bg-blue bold navbar-cta text-uppercase"
                      to="/login"
                    >
                      Acceder
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col col-md-4 col-xl-2 text-right pe-4 hide-mobile">
                <Link
                  className="btn bg-blue bold navbar-cta text-uppercase"
                  to="/login"
                >
                  Acceder
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
