import React from "react";
import { formatMonto, S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";

const CursoCard = ({ curso, purchase }) => {
  const renderExpiration = () => {
    if (purchase) {
      return (
        <div>
          <h5>Expira en:</h5>
          {moment(purchase.expiration_date).utc().format("DD MMM YYYY")}
        </div>
      );
    }
    return (
      <div>
        <h5>Accesso por:</h5>
        <p className="mb-0">{curso.expiration_days} días</p>
      </div>
    );
  };

  const { course_id, file, thumbnail, name, description, price, sale_price } =
    curso;
  const final_price =
    sale_price && sale_price !== null && sale_price !== "" ? sale_price : price;
  return (
    <Link
      to={
        purchase
          ? `/mylatina/cursos/${course_id}`
          : `/checkout/course/${course_id}`
      }
      className="col-12 col-md-6 col-xl-4 p-2 text-dark no-decoration mb-4"
    >
      <div className="curso-card">
        <div className="card bg-white shadow-sm p-3">
          {thumbnail !== null && (
            <img
              src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
              className="mw-100 w-100 mb-4 curso-img"
            />
          )}

          <div className="row align-items-center">
            <div className="col-8">
              <h3 className="bold">{name}</h3>
              <p>{description}</p>
              {!purchase && (
                <h4 className="bold mb-0">
                  {curso.price !== final_price && (
                    <del className="text-danger me-2 h6">
                      {"$"}
                      {formatMonto(curso.price)} MXN
                    </del>
                  )}
                  ${formatMonto(final_price)} MXN
                </h4>
              )}
            </div>
            <div className="col-4 text-end">{renderExpiration()}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CursoCard;
