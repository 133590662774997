import React, { useEffect, useState } from "react";
import SingleLesson from "../lessons/SingleLesson";

const CursoComprado = ({ curso, completeLesson }) => {
  const [lessonIndex, setLessonIndex] = useState(0);
  const [showSections, setShowSections] = useState(true);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedLeccion, setSelectedLeccion] = useState(null);

  useEffect(() => {
    return () => {
      setSelectedLeccion(null);
    };
  }, []);

  useEffect(() => {
    if (curso !== null && selectedSection === null) {
      if (curso.sections.length > 0) {
        setSelectedSection(curso.sections[0]);
      }
    }
  }, [curso]);

  useEffect(() => {
    if (selectedSection !== null) {
      if (selectedSection.lessons.length > 0) {
        setSelectedLeccion(selectedSection.lessons[0]);
        setLessonIndex(0);
      } else {
        setSelectedLeccion(null);
      }
    }
  }, [selectedSection]);

  const renderCourse = () => {
    if (curso && curso !== null) {
      return (
        <div className="p-3 pt-0 mb-4 border-bottom">
          <h1>{curso.name}</h1>
          <p className="mb-0">{curso.description}</p>
        </div>
      );
    }
  };

  const renderSections = () => {
    if (curso && curso !== null && showSections) {
      if (curso.sections.length === 0) {
        return <p>No hay secciones disponibles.</p>;
      }
      return curso.sections.map((section) => (
        <div
          key={section.section_id}
          className={
            "card shadow-sm no-scale my-2 " +
            (selectedSection !== null &&
            selectedSection.section_id === section.section_id
              ? "border-pink"
              : "")
          }
          onClick={() => {
            setSelectedSection(section);
          }}
        >
          <h6>Módulo #{section.order}</h6>
          <h3>{section.name}</h3>
          <p>{section.description}</p>
        </div>
      ));
    }
  };

  const renderLeccion = () => {
    if (selectedLeccion) {
      return (
        <SingleLesson
          lesson={selectedLeccion}
          completeLesson={completeLesson}
        />
      );
    }
  };

  const renderPrevButton = () => {
    if (selectedSection && selectedSection !== null) {
      if (
        selectedSection.lessons[0] &&
        selectedLeccion &&
        selectedLeccion !== null
      ) {
        if (
          selectedSection.lessons[0].lesson_id !== selectedLeccion.lesson_id
        ) {
          return (
            <button
              className="btn btn-light mx-2 shadow-sm"
              onClick={() => setLessonIndex(lessonIndex - 1)}
            >
              <i className="fa fa-chevron-left"></i>
            </button>
          );
        }
      }
    }
  };

  const renderNextButton = () => {
    if (selectedSection && selectedSection !== null) {
      if (
        selectedSection.lessons[selectedSection.lessons.length - 1] &&
        selectedLeccion &&
        selectedLeccion !== null
      ) {
        if (
          selectedSection.lessons[selectedSection.lessons.length - 1]
            .lesson_id !== selectedLeccion.lesson_id
        ) {
          return (
            <button
              className="btn btn-light mx-2 shadow-sm"
              onClick={() => setLessonIndex(lessonIndex + 1)}
            >
              <i className="fa fa-chevron-right"></i>
            </button>
          );
        }
      }
    }
  };

  return (
    <div className="row">
      <div className="col-lg-3 section-wrapper position-relative pt-4">
        {renderCourse()}
        <div className="section-container">
          <div className="row mx-0 align-items-center mb-3 show-mobile">
            <div className="col-6">
              <h4>Módulos</h4>
            </div>
            <div className="col-6 text-end">
              <button
                className="btn btn-light shadow-sm"
                onClick={() => setShowSections(!showSections)}
              >
                <i
                  className={`fa fa-chevron-${showSections ? "up" : "down"}`}
                ></i>
              </button>
            </div>
          </div>
          {renderSections()}
        </div>
        {/*<div className="add-section-card no-scale">
          <button className="btn btn-block btn-dark w-100">+ Sección</button>
        </div>*/}
      </div>
      <div className="col-lg-9 bg-light">
        {selectedSection !== null && selectedLeccion !== null && (
          <>
            <div className="row bg-white mb-2 lesson-wrapper">
              <div className="container-fluid">
                <div className="row align-items-center border-bottom py-4">
                  <div className="col-6">
                    <h3 className="mt-2">Lecciones</h3>
                  </div>
                  <div className="col-6 text-right">
                    {renderPrevButton()}
                    {renderNextButton()}
                  </div>
                </div>
              </div>
            </div>
            {renderLeccion()}
          </>
        )}
      </div>
    </div>
  );
};

export default CursoComprado;
