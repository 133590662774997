import { Link } from "@reach/router";
import React from "react";

const Certification = () => {
  return (
    <div className="container-fluid bg-dark text-center">
      <div className="row vh-100 align-items-center">
        <div className="container-fluid">
          <img
            src="/img/Latina_Logo2.png"
            className="mw-50 w-50 d-block m-auto"
          />
          <p className="text-white h3 my-3">
            En construcción. Pronto lanzaremos contenido en esta sección.
          </p>
          <Link to="/mylatina/cursos" className="btn bg-pink">
            Regresar a Cursos
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Certification;
