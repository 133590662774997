import React, { useContext, useEffect, useState } from "react";
import { CheckoutContext } from "../context/CheckoutContext";
import { MetodosContext } from "../context/MetodosContext";
import {
  S3_ENDPOINT,
  formatMonto,
  validateEmail,
  validatePhoneNumber,
} from "../utils";
import mastercard from "../assets/images/payment-method-mc.png";
import visa from "../assets/images/payment-method-visa.png";
import amex from "../assets/images/payment-method-amex.png";
import { Link } from "@reach/router";
import { UserContext } from "../context/UserContext";
import StripeCheckout from "../components/common/StripeCheckout";
import { ModalContext } from "../context/ModalContext";
import logo_latina from "../assets/images/latina_logo1.png";
import LoginForm from "../components/auth/LoginForm";
import SignUpForm from "../components/auth/SignUpForm";

const CheckoutPagos = ({ class_package_id }) => {
  //Singup and Login
  const [login, setLogin] = useState(false);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [password, setPassword] = useState("");
  const [apellidos, setApellidos] = useState("");
  const { spinner, user, signUp, signIn } = useContext(UserContext);

  const spinnerUsuario = useContext(UserContext).spinner;

  //Discounts
  const [codigo, setCodigo] = useState("");

  //Payments
  const { metodos } = useContext(MetodosContext);
  const { alert } = useContext(ModalContext);

  const [paymentMethod, setPaymentMethod] = useState("credit");

  //Checkout
  const { paquete, descuento } = useContext(CheckoutContext);

  useEffect(() => {
    //getPaquete(class_package_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    signIn(correo, password);
  };

  const handleSubmitSignUp = (e) => {
    e.preventDefault();
    if (nombre === "") {
      return alert("Debes ingresar tu nombre.");
    }
    if (apellidos === "") {
      return alert("Debes ingresar tus apellidos.");
    }
    if (!validateEmail(correo)) {
      return alert("El correo electrónico no es válido.");
    }
    if (String(password).length < 6) {
      return alert("La contraseña debe tener al menos 6 caracteres.");
    }
    if (!validatePhoneNumber(telefono)) {
      return alert("Debes ingresar un número de teléfono válido.");
    }
    signUp(nombre, apellidos, correo, password, telefono);
  };

  const renderCreateAccount = () => {
    if (user === null) {
      if (login) {
        return <LoginForm />;
      }
      return <SignUpForm signInAction={() => setLogin(true)} />;
    }
  };

  const renderImage = () => {
    if (paquete.file && paquete.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${paquete.file.name}.${paquete.files.type}`}
          className="mw-100 w-100 m-auto mb-3 paquete-thumbnail"
        />
      );
    }
  };

  const getSubscriptionInterval = (paquete) => {
    const { subscription_interval, subscription_period } = paquete;
    return subscription_period === "month"
      ? subscription_interval === 1
        ? "mes"
        : "meses"
      : subscription_period === "day"
      ? "días"
      : "años";
  };

  const getPackagePeriod = (paquete) => {
    return `cada ${paquete.subscription_interval} ${getSubscriptionInterval(
      paquete
    )}`;
  };

  const renderDiscountPeriod = (descuento, paquete) => {
    if (paquete.is_subscription) {
      if (
        !descuento.first_invoice_only ||
        descuento.first_invoice_only === null
      ) {
        return getPackagePeriod(paquete);
      }
      return `el primer ${getSubscriptionInterval(paquete)}`;
    }
  };

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let total = paquete.price;
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = parseFloat(paquete.price) * porcentaje;
      } else {
        total = paquete.price - descuento.amount;
      }
      total = parseFloat(total).toFixed(2);
      return (
        <div className="container-fluid">
          <h5>
            Total con Descuento: {"$"}
            {total} MXN {renderDiscountPeriod(descuento, paquete)}
          </h5>
          {descuento.first_invoice_only && (
            <p>
              Después ${formatMonto(paquete.price)} {getPackagePeriod(paquete)}
            </p>
          )}
        </div>
      );
    }
  };

  const renderMetodosPago = () => {
    if (metodos && metodos !== null) {
      return metodos.map((metodo) => (
        <div
          key={metodo.conekta_payment_source_id}
          className="card p-3 no-scale my-3"
        >
          <div className="row mx-0">
            <div className="col-1">
              <input
                type="radio"
                checked={paymentMethod === metodo}
                onChange={() => setPaymentMethod(metodo)}
              />
            </div>
            <div className="col-11">
              <div className="row align-items-center">
                <div className="col col-md-4">
                  <img
                    src={
                      metodo.card_type === "mastercard"
                        ? mastercard
                        : metodo.card_type === "visa"
                        ? visa
                        : amex
                    }
                    className="card-type"
                    alt="card type"
                  />
                </div>
                <div className="col col-md-4 capitalize">
                  {metodo.card_type}
                </div>
                <div className="col col-md-4">
                  {"**** "}
                  {metodo.last_digits}
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <>
          <h2>Forma de Pago</h2>
          <div className="card border shadow-sm no-scale my-3">
            <StripeCheckout element_id="certificacion" discountCode={codigo} />
          </div>
        </>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row pb-3 bg-dark pt-3">
        <div className="container-fluid mb-2 pb-2 border-bottom ">
          <div className="row align-items-center py-2">
            <div className="col-8 col-md-10">
              <h1 className="text-white">Checkout</h1>
            </div>
            <div className="col-4 col-md-2 text-right">
              <Link to="/">
                <img src={logo_latina} className="mw-100 w-100" alt="logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row bg-light pt-4 pb-5 h-80">
        <div className="col-12 col-md-4 my-2">
          <div className="card p-3 mb-3 no-scale shadow-sm">
            <img
              src={`${S3_ENDPOINT}/certi-foto.png`}
              className="mw-50 w-100 d-block m-auto mb-3"
            />
            <div>
              <h4>Latina Certificación</h4>
              <p className="large">Paga Ahora $3,000 MXN</p>
              <p>Después: $6,450 MXN cada quincena, por 2 quincenas</p>
              <p>Total: $15,900 MXN</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 my-2">
          {renderCreateAccount()}
          {renderPago()}
        </div>
        <div className="container-fluid">
          <div>
            <h4>Acerca de tu Facturación</h4>
            <p>
              Se te realizará un cargo automático a tu tarjeta de crédito o
              débito en 15 días de $6,450 MXN y otro en 15 días después de
              $6,450 MXN.
            </p>
            <p>
              Si tu tarjeta no pasa por alguna razón, te contactaremos. Si tu
              certificación no está liquidada para el 15 de Noviembre, no podrás
              asistir a la certificación.
            </p>
          </div>
        </div>
      </div>
      <div className="row bg-dark text-white py-2">
        <div className="container-fluid">
          <div className="col col-md-6"></div>
          <div className="col col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPagos;
