import {
  CREATE_SECTION,
  SECTIONS_RECIBIDAS,
  SET_PROPIEDAD_SECTION,
  SINGLE_SECTION_RECIBIDA,
} from "../types";

const schema = {
  idSection: "nueva",
  name: "",
  description: "",
  order: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case SECTIONS_RECIBIDAS:
      return { ...state, sections: payload };
    case SINGLE_SECTION_RECIBIDA:
      return { ...state, section: payload };
    case SET_PROPIEDAD_SECTION:
      const { key, value } = payload;
      const section = { ...state.section };
      section[key] = value;
      return { ...state, section };
    case CREATE_SECTION:
      return { ...state, section: schema };
    default:
      return { ...state };
  }
};
