import React, { useContext, useEffect, useState } from "react";
import CreditCardForm from "../components/common/CreditCardForm";
import { CheckoutContext } from "../context/CheckoutContext";
import { MetodosContext } from "../context/MetodosContext";
import {
  formatMonto,
  getCardType,
  getCoursePrice,
  S3_ENDPOINT,
} from "../utils";
import MetodosService from "../services/MetodosService";
import logo_latina from "../assets/images/latina_logo1.png";
import { Link } from "@reach/router";
import { UserContext } from "../context/UserContext";
import { ModalContext } from "../context/ModalContext";
import CheckoutMetodoCard from "../components/metodos/CheckoutMetodoCard";
import LoginForm from "../components/auth/LoginForm";
import SignUpForm from "../components/auth/SignUpForm";

const Checkout = ({ course_id }) => {
  //Singup and Login
  const [login, setLogin] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { user, signUp, signIn } = useContext(UserContext);

  const spinnerUsuario = useContext(UserContext).spinner;

  //Discounts
  const [codigo, setCodigo] = useState("");

  //Payments
  const { metodos, getMetodos, createToken } = useContext(MetodosContext);
  const [saveCard, setSaveCard] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("credit");
  const [card, setCard] = useState({
    name: "",
    number: "",
    expiry: "",
    cvc: "",
  });

  //Checkout
  const {
    descuento,
    paquete,
    curso,
    spinner,
    getCourseCheckout,
    spinnerDescuento,
    createOrder,
    setDescuento,
    showSpinner,
    hideSpinner,
    validarDescuento,
  } = useContext(CheckoutContext);

  //Modal
  const { alert } = useContext(ModalContext);

  useEffect(() => {
    getCourseCheckout(course_id);
  }, []);

  useEffect(() => {
    return () => {
      setDescuento(null);
    };
  }, []);

  useEffect(() => {
    if (curso !== null && curso !== null) {
      setDisabled(false);
    }
    if (user !== null && curso === null) {
      getCourseCheckout(course_id);
      getMetodos();
    }
  }, [user, curso]);

  const handleSubmitDescuento = (e) => {
    e.preventDefault();
    validarDescuento(codigo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNaN(paymentMethod)) {
      if (paymentMethod === "card") {
        const { name, number, expiry, cvc } = card;
        showSpinner();
        return createToken(
          name,
          number,
          expiry,
          cvc,
          (token) => postPago(number, token),
          (error) => {
            if (error.message_to_purchaser) {
              alert(error.message_to_purchaser);
            }
            hideSpinner();
          }
        );
      }
    }
    createOrder(
      curso,
      paymentMethod,
      descuento && descuento !== null ? descuento.code : codigo
    );
  };

  const postPago = (number, token) => {
    const last_digits = number.substring(15);
    const card_type = getCardType(number[0]);
    MetodosService.postMetodoPago(
      token.id,
      card_type,
      last_digits,
      saveCard
    ).then((res) => {
      createOrder(
        curso,
        res.data,
        descuento && descuento !== null ? descuento.code : codigo
      );
    });
  };

  const handleSubmitLogin = (correo, password) => {
    signIn(correo, password);
  };

  const handleSubmitSignUp = (nombre, correo, password, telefono) => {
    signUp(nombre, correo, password, telefono);
  };

  const renderCreateAccount = () => {
    if (user === null) {
      if (login) {
        return (
          <LoginForm
            handleSubmitLogin={handleSubmitLogin}
            setLogin={setLogin}
            signUpAction={() => setLogin(false)}
            spinner={spinnerUsuario}
          />
        );
      }
      return (
        <SignUpForm
          handleSubmitSignUp={handleSubmitSignUp}
          setLogin={setLogin}
          signInAction={() => setLogin(true)}
          spinner={spinnerUsuario}
        />
      );
    }
  };

  const renderFile = () => {
    if (curso.file && curso.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${curso.file.name}.${curso.file.type}`}
          className="mw-100 w-100 d-block m-auto mb-3"
        />
      );
    }
  };

  const renderCurso = () => {
    if (curso && curso !== null) {
      const final_price = getCoursePrice(curso);
      return (
        <div className="card no-scale mb-3 boder shadow-sm">
          {renderFile()}
          <h3>{curso.name}</h3>
          <p>{curso.description}</p>
          {curso.price !== final_price && (
            <p className="crossed text-danger">
              <del>
                {"$"}
                {formatMonto(curso.price)} MXN
              </del>
            </p>
          )}
          <h5>
            Total: {"$"}
            {formatMonto(final_price)}
            {" MXN"}
          </h5>
        </div>
      );
    }
  };

  const renderDescuento = () => {
    if (user !== null) {
      return (
        <form onSubmit={handleSubmitDescuento}>
          <div className="container-fluid py-4 px-0">
            <label className="bold">Código de Descuento</label>
            <input
              type="text"
              className="form-control w-50 mb-3"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn-outline-dark"
              disabled={spinnerDescuento}
            >
              {spinnerDescuento ? (
                <div className="spinner-border"></div>
              ) : (
                "Aplicar"
              )}
            </button>
          </div>
        </form>
      );
    }
  };

  const renderMetodosPago = () => {
    if (metodos && metodos !== null) {
      return metodos.map((metodo) => (
        <CheckoutMetodoCard
          key={metodo.conekta_payment_source_id}
          metodo={metodo}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
        />
      ));
    }
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <>
          <h2>Forma de Pago</h2>
          {renderMetodosPago()}
          <div className="card no-scale my-3">
            <div className="row">
              <div className="col-1">
                <input
                  type="radio"
                  checked={paymentMethod === "card"}
                  onChange={() => setPaymentMethod("card")}
                />
              </div>
              <div className="col-11">
                <h3>Tarjeta de Crédito / Débito</h3>
                {paymentMethod === "card" && (
                  <div>
                    <CreditCardForm
                      modifier={(key, value) =>
                        setCard({ ...card, [key]: value })
                      }
                      {...card}
                    />
                    <label className="d-block mt-3">
                      <input
                        type="checkbox"
                        checked={saveCard}
                        onChange={() => setSaveCard(!saveCard)}
                      />{" "}
                      Guardar esta tarjeta como método de pago.
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card p-3 no-scale my-3">
            <div className="row">
              <div className="col-1">
                <input
                  type="radio"
                  checked={paymentMethod === "paypal"}
                  onChange={() => setPaymentMethod("paypal")}
                />
              </div>
              <div className="col-6">
                <h3>PayPal</h3>
                <p
                  className="small mb-0"
                  style={{
                    display: paymentMethod === "paypal" ? "block" : "none",
                  }}
                >
                  *Se te cobrará un 6% adicional por comisión.
                </p>
              </div>
              <div className="col-5">
                <div
                  id="paypal-button"
                  style={{
                    visibility:
                      paymentMethod === "paypal" ? "visible" : "hidden",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-0 text-right">
            {paymentMethod !== "paypal" && (
              <button
                className="btn btn-success btn-lg bold"
                onClick={handleSubmit}
                disabled={spinner || disabled}
              >
                {spinner ? (
                  <div className="spinner-border"></div>
                ) : (
                  "Pagar Ahora"
                )}
              </button>
            )}
          </div>
        </>
      );
    }
  };

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let total = paquete.price;
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = parseFloat(paquete.price) * porcentaje;
      } else {
        total = paquete.price - descuento.amount;
      }
      total = parseFloat(total).toFixed(2);
      return (
        <div className="container-fluid px-0">
          <h5>
            Total con Descuento: {"$"}
            {total} MXN
          </h5>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row pb-3 bg-dark pt-3">
        <div className="container-fluid mb-2 pb-2 border-bottom ">
          <div className="row">
            <div className="col-8 col-md-10">
              <h1 className="text-white">Checkout</h1>
            </div>
            <div className="col-4 col-md-2">
              <Link to="/">
                <img src={logo_latina} className="mw-100 w-100" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row bg-light pt-4 pb-5 h-80">
        <div className="col-12 col-md-4 my-2">
          {renderCurso()}
          {renderResultadoDescuento()}
        </div>
        <div className="col-12 col-md-8 my-2">
          {renderCreateAccount()}
          {renderPago()}
        </div>
      </div>
      <div className="row bg-dark text-white py-2">
        <div className="container-fluid">
          <div className="col col-md-6">
            <Link to="/privacidad" className="text-white">
              Aviso de Privacidad
            </Link>
          </div>
          <div className="col col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
