import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";

const OrdenCard = ({ payment }) => {
  return (
    <tr className="border">
      <td>{payment.purchase_id}</td>
      <td>{payment.name}</td>
      <td>
        {"$"}
        {formatMonto(payment.amount)}
        {" MXN"}
      </td>
      <td>
        <StatusBadge status={payment.status} order_id={payment.order_id} />
      </td>
      <td>{moment(payment.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>
        {payment.expiration_date !== null &&
          moment(payment.expiration_date).format("DD MMM YYYY HH:mm")}
      </td>
    </tr>
  );
};

export default OrdenCard;
