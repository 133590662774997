import React, { useContext, useEffect } from "react";
import OrdenCard from "../components/ordenes/OrdenCard";
import { PurchasesContext } from "../context/PurchasesContext";

const Pagos = () => {
  const { purchases, getPurchases } = useContext(PurchasesContext);

  useEffect(() => {
    getPurchases();
  }, []);

  const renderOrdenes = () => {
    if (purchases && purchases !== null) {
      if (purchases.length === 0) {
        return <p>No haz hecho ninguna compra.</p>;
      }
      return purchases.map((orden) => (
        <OrdenCard key={orden.purchase_id} payment={orden} />
      ));
    }
  };

  return (
    <div className="container-fluid pt-4">
      <div className="mx-0 row align-items-center mb-3">
        <div className="container-fluid px-0">
          <h2>Mis Compras</h2>
        </div>
      </div>
      <div class="alert alert-pink alert-light border" role="alert">
        <i className="fa fa-info-circle me-2"></i> Si tienes una pregunta sobre
        una compra, contáctanos a @latina.tribe e indicanos tu{" "}
        <span className="bold">correo electrónico y #ID de compra</span>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className="bg-light border bold py-2">
              <th>#ID</th>
              <th>Compra</th>
              <th>Total</th>
              <th>Estado</th>
              <th>Fecha y Hora</th>
              <th>Expira en</th>
            </tr>
          </thead>
          <tbody>{renderOrdenes()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Pagos;
