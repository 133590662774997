import React, { useContext, useEffect } from "react";
import CursoComprado from "../components/cursos/CursoComprado";
import { CursosContext } from "../context/CursosContext";
import { LessonsContext } from "../context/LessonsContext";
import { UserContext } from "../context/UserContext";

const SingleCurso = ({ course_id }) => {
  const { curso, getSingleCurso } = useContext(CursosContext);
  const { completeLesson } = useContext(LessonsContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getSingleCurso(course_id);
  }, []);

  const renderCurso = () => {
    if (curso !== null && user !== null) {
      if (
        user.purchases.find(({ course_id }) => course_id === curso.course_id)
      ) {
        return (
          <CursoComprado
            curso={curso}
            completeLesson={(lesson_id) => completeLesson(lesson_id, course_id)}
          />
        );
      }
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="container-fluid">{renderCurso()}</div>;
};

export default SingleCurso;
