import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import { UserContext } from "../context/UserContext";
import MobileMenu from "../components/common/MobileMenu";
import PanelNavbar from "../components/global/PanelNavbar";
import Cursos from "./Cursos";
import SingleCurso from "./SingleCurso";
import MiInformacion from "./MiInformacion";
import MisMetodos from "./MisMetodos";
import Pagos from "./Pagos";
import Sorry from "./Sorry";
import Certification from "./Certification";

const Panel = () => {
  const { user, signOut, getUsuario } = useContext(UserContext);

  useEffect(() => {
    getUsuario();
  }, []);

  return (
    <div className="container-fluid px-0">
      <MobileMenu signOut={signOut} user={user} />
      <div className="sidebar">
        <PanelNavbar user={user} signOut={signOut} />
      </div>
      <div className="main-panel px-4">
        <div className="content overflow-x-hidden">
          <Router>
            <Cursos path="/cursos" default />
            <SingleCurso path="/cursos/:course_id" />
            <MiInformacion path="/informacion" />
            <MisMetodos path="/metodos" />
            <Pagos path="/pagos" />
            <Sorry path="/sorry" />
            <Certification path="/certification" />
          </Router>
        </div>
      </div>
    </div>
  );
};

export default Panel;
