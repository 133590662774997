import { CheckoutProvider } from "./context/CheckoutContext";
import { CursosProvider } from "./context/CursosContext";
import { LessonsProvider } from "./context/LessonsContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchasesProvider } from "./context/PurchasesContext";
import { SetctionsProvider } from "./context/SectionsContext";
import { UserProvider } from "./context/UserContext";
import Main from "./Main";

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <CursosProvider>
          <SetctionsProvider>
            <LessonsProvider>
              <MetodosProvider>
                <CheckoutProvider>
                  <PurchasesProvider>
                    <Main />
                  </PurchasesProvider>
                </CheckoutProvider>
              </MetodosProvider>
            </LessonsProvider>
          </SetctionsProvider>
        </CursosProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
