import { Link } from "@reach/router";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import { UserContext } from "../context/UserContext";

const GraciasCerti = ({ purchase_id }) => {
  const { purchase, getSinglePurchase } = useContext(PurchasesContext);

  const { user, userLoggedIn, getUsuario } = useContext(UserContext);

  useEffect(() => {
    userLoggedIn();
    getUsuario();
    getSinglePurchase(purchase_id);
  }, []);

  useEffect(() => {
    if (user !== null && purchase === null) {
      getUsuario();
      getSinglePurchase(purchase_id);
    }
  }, [user]);

  const renderCompra = () => {
    if (purchase && purchase !== null) {
      return (
        <div className="card no-scale my-3">
          <h4>Compra #{purchase.purchase_id}</h4>
          <p>Fecha: {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</p>
          <p>Total: ${purchase.amount} MXN</p>
          <p>
            Estado:{" "}
            {purchase.status === "success" ? (
              <span className="badge badge-pill bg-success">Exitosa</span>
            ) : (
              <span className="badge badge-pill bg-warning">Pendiente</span>
            )}
          </p>
          <p>
            Expira en:{" "}
            {moment(purchase.expiration_date).format("DD MMM YYYY HH:mm")}
          </p>
          <Link to="/mylatina" className="btn btn-dark">
            Ir a Mi Panel
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid py-5 bg-dark vh-100">
      <div className="container mw-500 my-5">
        <h1 className="text-center text-white">¡Gracias por tu Compra!</h1>
        <div className="card no-scale my-3">
          <h4>Compra #{purchase.purchase_id}</h4>
          <p>Fecha: {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</p>
          <p>Total: ${purchase.amount} MXN</p>
          <p>
            Estado:{" "}
            {purchase.status === "success" ? (
              <span className="badge badge-pill bg-success">Exitosa</span>
            ) : (
              <span className="badge badge-pill bg-warning">Pendiente</span>
            )}
          </p>
          <p>
            Expira en:{" "}
            {moment(purchase.expiration_date).format("DD MMM YYYY HH:mm")}
          </p>
          <Link to="/mylatina" className="btn btn-dark">
            Ir a Mi Panel
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GraciasCerti;
