import React from "react";

const PanelView = ({ title, children, onClick, buttonTitle }) => {
  return (
    <div className="container-fluid pt-4">
      <div className="row border-bottom pb-2 mb-3">
        <div className="col-8">
          <h1>{title}</h1>
        </div>
        <div className="col-4 text-right">
          {onClick && (
            <button className="btn btn-dark" onClick={onClick}>
              {buttonTitle}
            </button>
          )}
        </div>
      </div>
      <div className="bg-light panel-content">{children}</div>
    </div>
  );
};

export default PanelView;
