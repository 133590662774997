import React from "react";
import { Link } from "@reach/router";

const PanelNavbar = ({ user, signOut }) => {
  return (
    <nav className="bg-dark hide-mobile side-menu">
      <div className="container-fluid px-0">
        <div className="px-3">
          <Link to="/" className="navbar-brand" href="#landing">
            <img src="/img/Latina_Logo2.png" className="mw-100 w-100" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="text-white mt-4">
          <ul className="side-menu-list">
            <li className="nav-item text-item">
              <Link to="/mylatina/reservaciones" className="nav-link">
                Cursos
              </Link>
            </li>
            {user !== null && user.isAdmin && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarAdmin"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Admin
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarAdmin">
                  <li>
                    <Link to="./admin/clases" className="dropdown-item">
                      Cursos
                    </Link>
                  </li>
                  <li>
                    <Link to="./admin/clientes" className="dropdown-item">
                      Clientes
                    </Link>
                  </li>
                  <li>
                    <Link to="./admin/descuentos" className="dropdown-item">
                      Descuentos
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user !== null && user.isManager && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarManager"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Manager
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarManager">
                  <li>
                    <Link to="./admin/asistentes" className="dropdown-item">
                      Asistentes
                    </Link>
                  </li>
                  <li>
                    <Link to="./admin/clientes" className="dropdown-item">
                      Clientes
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user !== null && user.isAdmin && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarAnaliticas"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Analiticas
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarAnaliticas"
                >
                  <li>
                    <Link to="./analiticas/inscritos" className="dropdown-item">
                      Inscritos
                    </Link>
                  </li>
                  <li>
                    <Link to="./analiticas/ingresos" className="dropdown-item">
                      Ingresos
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {String(user.name).substr(0, 20)}
                {String(user.name).length > 20 ? "..." : ""}
                <i className="ms-2 fa fa-user-circle"></i>
              </a>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link to="./informacion" className="dropdown-item">
                    Mi Información
                  </Link>
                </li>
                <li>
                  <Link to="./pagos" className="dropdown-item">
                    Mis Compras
                  </Link>
                </li>
                <li>
                  <Link to="./metodos" className="dropdown-item">
                    Mis Métodos de Pago
                  </Link>
                </li>
                <li className="mt-3">
                  <button
                    className="dropdown-item text-danger"
                    onClick={signOut}
                  >
                    Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
