import api from "./api";
import firebase from "firebase";
import "firebase/auth";
import { TOKEN_KEY } from "../utils";

const firebaseConfig = {
  apiKey: "AIzaSyDYvcNCNPE0-vFlg6kyvsX01orDMK1XDF0",
  authDomain: "latina-cert.firebaseapp.com",
  projectId: "latina-cert",
  storageBucket: "latina-cert.appspot.com",
  messagingSenderId: "255998014371",
  appId: "1:255998014371:web:49c086dc76eeda3b02a016",
  measurementId: "G-VCRXHGZQ94",
};

// Initialize Firebase
if (firebase.apps.length < 1) {
  firebase.initializeApp(firebaseConfig);
}

const getToken = () => auth.currentUser.getIdToken(true);

const auth = firebase.auth();

const AuthService = {
  signIn: (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        return getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          return user;
        });
      }),
  userLoggedIn: (success, error) =>
    auth.onAuthStateChanged((user) => {
      if (user) {
        getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          if (success) success(user);
        });
      } else {
        error();
      }
    }),
  signOut: () => {
    //window.localStorage.setItem(TOKEN_KEY, null);
    return auth.signOut();
  },
  signUp: (correo, password) =>
    auth.createUserWithEmailAndPassword(correo, password),
  recoverPassword: (email) => auth.sendPasswordResetEmail(email),
  getToken: () => auth.currentUser.getIdToken(true),
  verifyEmail: () => auth.currentUser.sendEmailVerification(),
  updateEmail: (email) => auth.currentUser.updateEmail(email),
};

export default AuthService;
