import React from "react";
import parse from "html-react-parser";

const AccordionItem = ({ id, title, content, parentId }) => {
  const headerId = `head-${id}`;
  return (
    <div class="accordion-item">
      <h2 class="accordion-header" id={headerId}>
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-expanded="false"
          aria-controls={id}
        >
          {title}
        </button>
      </h2>
      <div
        id={id}
        class="accordion-collapse collapse"
        aria-labelledby={headerId}
        data-bs-parent={parentId}
      >
        <div class="accordion-body">{parse(content)}</div>
      </div>
    </div>
  );
};

const FAQ = ({ id, items }) => {
  const renderItems = () => {
    return items.map(({ title, htmlContent }, index) => (
      <AccordionItem
        key={index}
        id={`faq-${index + 1}`}
        title={title}
        content={htmlContent}
        parentId={id}
      />
    ));
  };

  return (
    <div class="accordion accordion-flush" id={id}>
      {renderItems()}
    </div>
  );
};

export default FAQ;
