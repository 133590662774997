import { Link } from "@reach/router";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import { UserContext } from "../context/UserContext";
import { S3_ENDPOINT } from "../utils";

const ThankYou = ({ purchase_id }) => {
  const { purchase, getSinglePurchase } = useContext(PurchasesContext);

  const { user, userLoggedIn, getUsuario } = useContext(UserContext);

  useEffect(() => {
    userLoggedIn();
    getUsuario();
    getSinglePurchase(purchase_id);
  }, []);

  useEffect(() => {
    if (user !== null && purchase === null) {
      getUsuario();
      getSinglePurchase(purchase_id);
    }
  }, [user]);

  const renderCompra = () => {
    if (purchase && purchase !== null) {
      if (purchase.course_purchase && purchase.course_purchase !== null) {
        if (
          ["success", "active", "complete", "completed"].includes(
            purchase.status
          )
        ) {
          if (purchase.course_purchase.course_id === 2) {
            if (purchase.payment_method_id === 3) {
              return (
                <img
                  src={`${S3_ENDPOINT}/instructor-recibo-pagos.png`}
                  className="mw-100 w-100 d-block m-auto"
                />
              );
            }
            return (
              <img
                src={`${S3_ENDPOINT}/instructor-recibo-contado.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
          if (purchase.course_purchase.course_id === 3) {
            if (purchase.payment_method_id === 3) {
              return (
                <img
                  src={`${S3_ENDPOINT}/certi-recibo-pagos.png`}
                  className="mw-100 w-100 d-block m-auto"
                />
              );
            }
            return (
              <img
                src={`${S3_ENDPOINT}/certi-recibo-contado.png`}
                className="mw-100 w-100 d-block m-auto"
              />
            );
          }
        }
      }
      return (
        <div className="card no-scale my-3">
          <h1>¡Gracias por tu Compra!</h1>
          <h4>Compra #{purchase.purchase_id}</h4>
          <p>Fecha: {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</p>
          <p>Total: ${purchase.amount} MXN</p>
          <p>
            Estado:{" "}
            {purchase.status === "success" ? (
              <span className="badge badge-pill bg-success">Exitosa</span>
            ) : (
              <span className="badge badge-pill bg-warning">En Proceso</span>
            )}
          </p>
          <p>
            Expira en:{" "}
            {moment(purchase.expiration_date).format("DD MMM YYYY HH:mm")}
          </p>
          <Link to="/mylatina" className="btn btn-dark">
            Ir a Mi Panel
          </Link>
        </div>
      );
    }
    return <div className="spinner-border text-white"></div>;
  };

  return (
    <div className="container-fluid py-3 bg-dark vh-100">
      <div className="container mw-500 my-5">{renderCompra()}</div>
    </div>
  );
};

export default ThankYou;
