import React from "react";
import { S3_ENDPOINT } from "../../utils";

const PromoBanner = () => {
  return (
    <div className="container-fluid bg-blue text-dark p-4">
      <div className="row align-items-center impact scroll-banner">
        <div className="col-2 col-sm-4 col-md-3 col-lg-2 text-center">
          <span className="h3">50% OFF</span>
        </div>
        <div className="col-4 col-md-3 col-lg-2 text-center">
          <img
            src={`${S3_ENDPOINT}/instructor-20.png`}
            className="mw-25 w-75 d-block m-auto"
          />
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 h3 text-center">
          <span>CURSO ONLINE</span>
          <br />
          <span>PRE-CERTIFICACIÓN</span>
        </div>
        <div className="col-4 col-md-3 col-lg-2 hide-mobile text-center">
          <span className="h3">50% OFF</span>
        </div>
        <div className="col-4 col-md-3 col-lg-2 hide-mobile text-center">
          <img
            src={`${S3_ENDPOINT}/instructor-20.png`}
            className="mw-25 w-75 d-block m-auto"
          />
        </div>
        <div className="col-4 col-md-3 col-lg-2 hide-mobile h3 text-center">
          <span>CURSO ONLINE</span>
          <br />
          <span>PRE-CERTIFICACIÓN</span>
        </div>
      </div>
    </div>
  );
};

export default PromoBanner;
