import React, { createContext, useReducer } from "react";
import PurchasesReducer from "../reducers/PurchasesReducer";
import PurchasesService from "../services/PurchasesService";
import { PURCHASES_RECEIVED, SET_PURCHASE } from "../types";

const initialState = {
  purchaes: null,
  purchase: null,
};

export const PurchasesContext = createContext(initialState);

export const PurchasesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PurchasesReducer, initialState);

  const getSinglePurchase = (purchase_id) => {
    PurchasesService.getSinglePurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_PURCHASE, payload: purchase });
    });
  };

  const getPurchases = () => {
    PurchasesService.getPurchases().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: PURCHASES_RECEIVED, payload: purchases });
    });
  };

  return (
    <PurchasesContext.Provider
      value={{ ...state, getPurchases, getSinglePurchase }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
